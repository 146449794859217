import controllerHome from "@router/controllers/home"
import controllerCitizen from "@router/controllers/citizen"
import controllerTourist from "@router/controllers/tourist"
import controllerVisiting from "@router/controllers/visiting"
import controllerBusiness from "@router/controllers/business"
import store from "@state/store";
import {lazyLoadView} from "@utils/views";

/**
 * Выполняется перед роутами
 *
 * @param next
 * @return {Promise<void>}
 */
export async function beforeActionWithAuth(next) {
    return next();
}

export default [
    controllerHome,
    controllerCitizen,
    controllerTourist,
    controllerVisiting,
    controllerBusiness,

    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
        path: '*',
        component: () => lazyLoadView(import('@views/error404.vue')),
    },
]
