import {lazyLoadView} from "@utils/views";

export default {
    path: '/visiting',
    name: 'visiting',
    component: () => lazyLoadView(import('@views/visiting.vue')),
    meta: {
        authRequired: false,
        beforeResolve(routeTo, routeFrom, next) {
            return next();
        },
    }
}
