import {lazyLoadView} from "@utils/views";

export default {
    path: '/business',
    name: 'business',
    component: () => lazyLoadView(import('@views/business.vue')),
    meta: {
        authRequired: false,
        beforeResolve(routeTo, routeFrom, next) {
            return next();
        },
    }
}
